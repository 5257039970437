@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* fonts */

@import "brandon_text.scss";

.jumbotron {
  // should be relative path of the entry scss file
  background-image: url("../../vendors/images/sample.jpg");
  background-size: cover;
}

html {
  font-family: "brandon_text", monospace;
  scroll-behavior: smooth;
}

h1 {
  @apply text-4xl;
  @apply font-bold;
}

h2 {
  @apply text-3xl;
  @apply pb-4;
}

h3 {
  @apply uppercase;
  @apply font-bold;
  @apply tracking-widest;
  @apply text-lg;
  @apply pb-4;
  @apply pt-4;
}

.uppercase {
  @apply tracking-widest;
}

.button {
  @apply rounded-full;
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  @apply px-5;
  @apply py-2;
  @apply text-base;
  @apply font-medium;

  &:hover {
    @apply transition-all;
  }
}

.animate-underline-on-hover {
  display: inline-block;
  position: relative;

  &.dark-underline::after {
    @apply bg-teal-500;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: white;
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.button-teal {
  @apply bg-teal-500;
  @apply text-white;

  &:hover {
    @apply bg-teal-600;
  }
}

.button-blue {
  @apply bg-blue-500;
  @apply text-white;

  &:hover {
    @apply bg-blue-600;
  }
}

.button-black {
  @apply bg-black-800;
  @apply text-white;

  &:hover {
    @apply bg-black-900;
  }
}

.richtext-image.left {
  float: left;

  @apply mr-8;
}

.richtext-image.right {
  float: right;

  @apply ml-8;

  &::before {
    content: "";
    clear: both;
    display: table;
  }
}

.bg-slant-top {
  position: relative;
  z-index: 1;

  &::before {
    background: inherit;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden; // for Chrome Windows
    top: 0;
    transform: skewY(-3.25deg);
    transform-origin: 0%;
  }
}

.bg-caret-top {
  position: relative;
}

.bg-caret-top::before {
  content: '';
  position: absolute;
  top: -1rem;
  left: 48%;
  height: 2rem;
  width: 2rem;
  transform: rotate(45deg);
  z-index: 98;
}

.bg-caret-top.bg-lime::before {
  background-color: theme('colors.lime.300');
}

.text-white-important,
.text-white-important a,
.text-white-important h1,
.text-white-important h2,
.text-white-important h3,
.text-white-important h4,
.text-white-important ol li::marker,
.text-white-important .prose {
  color: white !important;
}

.index-page .block-paragraph.body-block,
.block-multi-column-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.post img {
  @apply mx-auto;
}

.block-paragraph,
.block-multi-column-text {
  @apply px-6;
  @apply lg:px-0;

  * {
    @apply mx-auto;
  }

  h2,
  h3,
  h4,
  h5 {
    max-width: 52.5rem; /* magic number that matches max-w-prose for base text */
  }

  p,
  ul,
  ol,
  blockquote {
    @apply max-w-prose;
    @apply mb-4;
  }

  a {
    @apply text-blue-500;
  }

  + .split-image-section {
    @apply mt-12;
  }
}

.block-quote {
  @apply max-w-prose;
  @apply mb-6;
  @apply italic;
  @apply pl-4;
  @apply border-l-4;
  @apply border-gray-300;
  @apply prose-lg;
}

.block-page-section .block-page-section--content > .button-block,
.block-paragraph + .block-horizontal-stack {
  max-width: 52.5rem; /* magic number that matches max-w-prose for base text */
  width: auto;

  @apply mx-auto;
}

.block-truncated-content.collapsed {
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
  mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.block-truncated-content {
  > * {
    @apply mx-auto;
  }
}

.endorsement-text .block-paragraph {
  padding-left: 0;
}

.endorsement-text .block-paragraph p {
  @apply ml-0;
  @apply mb-4;
  @apply prose-lg;
}

.block-paragraph iframe {
  @apply aspect-video;
  @apply min-w-[80vw];
  @apply min-h-[45vh];
}

table {
  @apply table;
  @apply table-auto;
  @apply w-full;
  @apply max-w-5xl;
  @apply mb-4;
  @apply text-gray-900;
  @apply mx-auto;

  th {
    @apply bg-sky;
    @apply px-6;
    @apply py-4;
    @apply text-left;
  }

  td {
    @apply px-6;
    @apply py-4;
  }

  tr:nth-child(odd) td {
    @apply bg-gray-100;
  }

  tr:nth-child(even) td {
    @apply bg-white;
  }
}

/* theming for dark backgrounds */
.block-page-section.bg-themeNavyBlue,
.block-page-section.bg-themePlum,
.block-page-section.bg-themeBlack,
.block-page-section.bg-themeDarkGreen {
  /* for svg icons */
  svg {
    @apply fill-sky;
  }
}

.block-page-section.bg-themePlum .block-number_with_icon {
  .number {
    @apply text-lime-300;
  }

  .description {
    @apply text-sky;
  }
}

// making the top nav items look visually centered
.topnav-item:first-child {
  @apply lg:ml-16;
}

// get rid of default margin on paragraph for icon subhead and text element
.icon-subhead-and-text .text p {
  margin-top: 0;
}

// fundraiser page
.fundraiser-page {
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;

  h1,
  h2 {
    letter-spacing: 0.05em;
  }

  h1 {
    font-style: normal;
    text-transform: uppercase;
  }

  &.hero {
    line-height: 1;

    h1 {
      font-size: 7rem;
      font-weight: 1000;
    }

    h2 {
      font-weight: 600;
      font-style: italic;
      font-size: 2rem;
    }
  }

  &.body-content {
    h2 {
      font-weight: 900;
    }

    h3,
    h4 {
      font-weight: 900;
      letter-spacing: 0.1em;
    }
  }

  #host-committee .block-multi-column-text p {
    margin-bottom: 0.1em;
    margin-top: 0.1em;
  }
}
